import React from 'react'
import { graphql } from 'gatsby'
import { PageLayout, ProductFamilyPage } from '../components'

const ProductFamilyPageTemplate = ({ data, pageContext }) => {
  const { lang } = pageContext

  return (
    <PageLayout lang={lang} switcher={data.shopProductFamily}>
      <ProductFamilyPage
        productfamily={data.shopProductFamily}
        items={data.allShopSku ? data.allShopSku.edges.map(({ node }) => node) : null}
        attributes={data.allShopAttribute.edges.map(i => i.node)}
        attribute_groups={data.allShopAttributeGroup.edges.map(i => i.node)}
        lang={lang} />
    </PageLayout>
  )
}

export default ProductFamilyPageTemplate

export const query = graphql`
  query ProductFamilyPageTemplateQuery($productfamily: String!, $id_productfamily: Int!) {
    shopProductFamily(id: { eq: $productfamily }) {
      ...ProductFamilyItem
    }
    allShopSku(filter: {id_productfamily: {eq: $id_productfamily}, active: {eq: 1}}) {
      edges {
        node {
          ...SKUItem
        }
      }
    }
    allShopAttributeGroup {
      edges {
        node {
          group_type
          id_attribute_group
          is_color_group
          name_en
          name_nl
          position
          public_name_en
          public_name_nl
        }
      }
    }
    allShopAttribute {
      edges {
        node {
          id_attribute
          id_attribute_group
          color
          name_en
          name_nl
          position
        }
      }
    }
  }
`
